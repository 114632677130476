
.sliderContainer {
  position: relative;
  
}

.rightArrow_slider {
  position: absolute;
  top: 50%;
  right: -12px;
  cursor: pointer;
  z-index: 10;
}

.leftArrow_slider {
  position: absolute;
  top: 50%;
  left: -12px;
  cursor: pointer;
  z-index: 10;
}

.imgOnSlider {
  overflow: hidden;
  border-radius: 24px;
  transition: all 0.8s;
  width: 1000px;
  height: 500px;
}

.dotContainer {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 95px;
  left: 140px;
  transform: translate(-50%, 0);
  transition: all 0.8s;
}

.dotNotClicked {
  width: 20px;
  height: 6px;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.8s;
  background-color: rgba(150, 150, 150, 0.65);
}

.dotClicked {
  background-color: white;
  z-index: 22;
  height: 6px;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.8s;
  width: 44px;
}
